import React, { forwardRef } from 'react';

type Props = { color?: string; size?: number; stroke?: number; className?: string };

const Camera = forwardRef(
  (
    { color = 'currentColor', size = 24, stroke = 1, ...rest }: Props,
    ref: React.LegacyRef<SVGSVGElement> | undefined,
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-camera"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...rest}
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
        <circle cx={12} cy={13} r={3} />
      </svg>
    );
  },
);

Camera.displayName = 'Camera';

export default Camera;
