import React, { ReactNode } from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import CircleCheck from 'src/icons/circle-check';
import CircleX from 'src/icons/circle-x';
import {
  toastIconHolderCss,
  toastCss,
  toastDescriptionCss,
  toastTitleCss,
  toastViewportCss,
} from '@/styles/toast.css';

type ToastProps = {
  open: boolean | undefined;
  onOpenChange?: (open: boolean) => void;
  children: ReactNode;
};

export function SuccessToast({ open, onOpenChange, children }: ToastProps) {
  return (
    <ToastPrimitive.Provider swipeDirection="right" duration={3000}>
      <ToastPrimitive.Toast className={toastCss} open={open} onOpenChange={onOpenChange}>
        <div className={toastIconHolderCss}>
          <CircleCheck className="text-grass11" />
        </div>
        <ToastPrimitive.Title className={toastTitleCss}>Success!</ToastPrimitive.Title>
        <ToastPrimitive.Description className={toastDescriptionCss}>
          {children}
        </ToastPrimitive.Description>
      </ToastPrimitive.Toast>
      <ToastPrimitive.Viewport className={toastViewportCss} />
    </ToastPrimitive.Provider>
  );
}

export function ErrorToast({ open, onOpenChange, children }: ToastProps) {
  return (
    <ToastPrimitive.Provider swipeDirection="right" duration={3000}>
      <ToastPrimitive.Toast className={toastCss} open={open} onOpenChange={onOpenChange}>
        <div className={toastIconHolderCss}>
          <CircleX className="text-red11" />
        </div>
        <ToastPrimitive.Title className={toastTitleCss}>Sorry!</ToastPrimitive.Title>
        <ToastPrimitive.Description className={toastDescriptionCss}>
          {children}
        </ToastPrimitive.Description>
      </ToastPrimitive.Toast>
      <ToastPrimitive.Viewport className={toastViewportCss} />
    </ToastPrimitive.Provider>
  );
}
