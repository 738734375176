import React, { forwardRef } from 'react';

type Props = { color?: string; size?: number; stroke?: number; className?: string };

const CircleCheck = forwardRef(
  (
    { color = 'currentColor', size = 24, stroke = 1, ...rest }: Props,
    ref: React.LegacyRef<SVGSVGElement> | undefined,
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-circle-check"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        strokeWidth={stroke}
        stroke={color}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...rest}
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx={12} cy={12} r={9} />
        <path d="M9 12l2 2l4 -4" />
      </svg>
    );
  },
);

CircleCheck.displayName = 'CircleCheck';

export default CircleCheck;
